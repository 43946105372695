<template>
  <div class="con">
    <svg height="210" width="500">
      <polygon
        points="200,10 250,190 160,210"
        style="fill:lime;stroke:red;stroke-width:1"
      />
    </svg>

    <svg>
      <rect x="15" y="15" width="40" height="40" fill="red">
        <set attributeName="fill" to="blue" begin="click" />
      </rect>
    </svg>

    <div class="s-splitter">
      <Splitter horizontal>
        <template v-slot:before>
          <div class="">
            <h1>Hello</h1>
          </div>
        </template>
        <template v-slot:after>
          <div class="s-pa-sm">
            <h1>Hello</h1>
          </div>
        </template>
      </Splitter>
    </div>
  </div>
</template>

<script>
import Splitter from "@c/Splitter";
import { reactive, toRefs, onMounted } from "vue";
export default {
  name: "su-sudidi-svg",
  components: {
    Splitter,
  },
  setup() {
    const state = reactive({
      tmp: "",
    });

    onMounted(() => {});

    return {
      ...toRefs(state),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "./index.scss";
</style>
